//* { box-sizing: border-box; }
.video-background {
  //background: #000;
  //position: fixed;
  //top: 0; right: 0; bottom: 0; left: 0;
  //z-index: -99;
width:100vw;
height:90vh;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
//#vidtop-content {
//  top: 0;
//  color: #fff;
//}
//.vid-info { position: absolute; top: 0; right: 0; width: 33%; background: rgba(0,0,0,0.3); color: #fff; padding: 1rem; font-family: Avenir, Helvetica, sans-serif; }
//.vid-info h1 { font-size: 2rem; font-weight: 700; margin-top: 0; line-height: 1.2; }
//.vid-info a { display: block; color: #fff; text-decoration: none; background: rgba(0,0,0,0.5); transition: .6s background; border-bottom: none; margin: 1rem auto; text-align: center; }
//@media (min-aspect-ratio: 16/9) {
//  .video-foreground { height: 300%; top: -100%; }
//}
//@media (max-aspect-ratio: 16/9) {
//  .video-foreground { width: 300%; left: -100%; }
//}
//@media all and (max-width: 600px) {
//  .vid-info { width: 50%; padding: .5rem; }
//  .vid-info h1 { margin-bottom: .2rem; }
//}
//@media all and (max-width: 500px) {
//  .vid-info .acronym { display: none; }
//}

.swiper-container {
  height : 400px; // old browsers
  height : 70vh; // overruled by style
  & .swiper-slide {
    -webkit-background-size : cover;
    background-size         : cover;
    background-position     : center;
    & .caption {
      position         : absolute;
      left             : 60px;
      bottom           : 60px;
      max-width        : 50%;
      background-color : rgba(0, 0, 0, 0.40);
      color            : white;
      padding          : 22px 32px;
      font-size        : @font-size-base*1.3;
      text-shadow      : @carousel-text-shadow;
      border-radius    : 12px;
      & h1, & h2, & h3, & h4, & h5 {
        color : white;
        &:first-child {
          margin-top : 0;
        }
      }
      & h1 {
        color  : white;
        margin : 4px 0px;
      }
      & h2 {
        color          : rgba(255, 255, 255, 0.7);
        text-transform : none;
        margin         : 4px 0px;
      }
      & h3 {
        color          : rgba(255, 255, 255, 0.5);
        text-transform : none;
        margin         : 7px 0px;
      }
    }
  }
  & .swiper-pagination {
    & .swiper-pagination-bullet {
      background-color : transparent;
      opacity          : 0.5;
      width            : 15px;
      height           : 15px;
      border           : 1px solid #fff;
    }
    & .swiper-pagination-bullet-active {
      background-color : @brand-primary;
      opacity          : 1;
    }
  }

  &.inpage {
    height : auto;
    & .swiper-slide {
      background-color : #e3eef7;
      color            : @text-color;
      font-family      : "Lato", Helvetica, Arial, sans-serif;
      font-size        : @font-size-base;
      //padding: @padding*1.5 @padding*2;
    }
  }

}

@media (max-width : @screen-sm-max) {
  .swiper-container {
    & .swiper-slide {
      font-size : 2.2vh;
      & .caption {
        right  : 60px;
        bottom : 30px;
      }
    }
  }
}

.fullbackground {
  position                : relative;
  -webkit-background-size : cover;
  background-size         : cover;
  background-position     : center;

  & .caption {
    position         : absolute;
    //z-index:1000;
    left             : 60px;
    bottom           : 60px;
    max-width        : 50%;
    background-color : rgba(0, 0, 0, 0.40);
    color            : rgba(255,255,255,0.8);
    padding          : 22px 32px;
    font-size        : @font-size-base*1.2;
    text-shadow      : @carousel-text-shadow;
    border-radius    : 6px;
    & h1, & h2, & h3, & h4, & h5 {
      color : white;
      &:first-child {
        margin-top : 0;
      }
    }
    & h1 {
      color  : #fff;//@brand-primary;
      margin : 4px 0px 8px;
    }
    & h2 {
      color          : rgba(255, 255, 255, 0.7);
      text-transform : none;
      margin         : 4px 0px;
    }
    & h3 {
      color          : rgba(255, 255, 255, 0.5);
      text-transform : none;
      margin         : 7px 0px;
    }
  }
}

@media (max-width : @screen-md-max) {
  .fullbackground {
    & .caption {
      left             : 30px;
      bottom           : 30px;
      right           : 30px;
      max-width        : 100%;
      padding          : 16px 22px;
      font-size        : @font-size-base;
      text-shadow      : @carousel-text-shadow;
      & p, & ul {
        display:none;
      }
      & h1 {
        font-size:@font-size-h1*0.7;
      }
      & h2 {
        font-size:@font-size-h2*0.7;
      }
      & h3 {
        font-size:@font-size-h3*0.7;
      }
    }
  }
}