@import "bootstrap/bootstrap";


/*
* Theme
*/
@import "variables"; // Overrides
@import "variables_de"; // Overrides


@import "/public/fonts/font-awesome/css/font-awesome.min.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400italic,600,700,800|Open+Sans+Condensed:300,700');

//body, label, .checkbox label {
//  font-weight: 400;
//}

body {
  //padding-top: @navbar-height+34;
  //background-repeat:no-repeat;
  //background-position: center top;
  //background-attachment: fixed;
  //
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;

  //-webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))  //font-size:14px;
}

html {
}

.reveal {
  visibility: hidden;
}

@import "helpers";
@import "type";

@import "navigation";

@import "onepage";
@import "isotope";
@import "social";
@import "pdf";

@import "preowned";
@import "products";

@import "forms";

@import "jasny/jasny-bootstrap";


@import (less) "swiper.min.css";
@import "swiper";

@import (less) "lightgallery/lightgallery.css";

//@import (less) "photoswipe/photoswipe.css";
//@import "photoswipe/default-skin/default-skin.css";
//@import "photoswipe";

//@import url("//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700|Open+Sans:300,400,400italic,600,700,800");
//@btn-font-weight: 400;
