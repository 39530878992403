.products {

  &.browse {
    & a {
      & h2 {
        text-transform: uppercase;
      }
      & h3 {
        text-transform:none;
        font-size: @font-size-base*1.1 !important;
      }
    }
    .table {
      td:first-child {
        font-weight:700;
        width:33%;
      }
    }
  }
  &.detail {
    & .container {
      padding: @padding-large-vertical*4 @padding-large-horizontal*2
    }

    & .specs {
      & ul {
        .list-unstyled();
        & li {
          border-top: 1px solid @gray-lighter;
          &:first-child {
            border-top: none;
          }
          & a {
            display: block;
            font-size: @font-size-base;
            font-weight: 700;
            text-transform: uppercase;
            padding: @padding-small-vertical*0.75 0;
            color: @text-color;
            &:hover {
              color: @brand-primary;
            }
            &:after {
              font-family: FontAwesome;
              content: "\f068";
              position: absolute;
              right: 10px;
              color: @brand-primary;
            }
            &.collapsed:after {
              content: "\f067";
            }
          }
          & ul {
            padding: @padding-small-vertical*0.75 0;
            & li {
              border: none;
              padding: @padding-small-vertical*0.75 0;
            }
          }
        }
      }
    }
    & .tabpanel {
      & h3 {
        line-height:2;
        border-bottom:1px solid darken(@gray-lighter,10%);
      }
      & ul {
        border-left: 3px solid @brand-primary;
        & li {
          & a {
            text-transform: uppercase;
          }
          &:hover a {
            background-color: transparent;
            color:@brand-primary;
          }
          &.active a {
            background-color: transparent;
            color:@brand-primary;
            //&:after {
            //  font-family: FontAwesome;
            //  content: "\f061";
            //  position: absolute;
            //  right: 10px;
            //  color: @brand-primary;
            //}
          }
        }
      }
      & div.tabcontent {
        & h2 {

        }
        & h3 {

        }
      }
    }

  }
}

