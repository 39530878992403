.navigation {
  z-index  : 1000;
  position : absolute;
  width    : 100%;

  & .topnav {
    //z-index          : 1000;
    width            : 100%;
    overflow         : visible;
    height           : 40px;
    line-height      : 40px;
    font-size        : @font-size-base*.9;
    background-color : @gray-darker; //@brand-primary;

    & form {
      margin : 0;
    }

    & ul.list.list-inline {
      margin : 0px 4px;
      & li {
        padding-left  : 8px;
        padding-right : 8px;
        color         : rgba(255, 255, 255, 0.65);
        &.social {
          border-left : 1px solid @gray;
          height      : Inherit;
          line-height : Inherit;
          &:last-child {
            border-right : 1px solid @gray;
          }
          & a {
            font-size : 140%;
            & i {
            }
          }
        }
        & a {
          color : rgba(255, 255, 255, 0.75);
        }
        &:hover a {
          color : rgba(255, 255, 255, 1);
          & i {
            color : @brand-primary;
          }
        }
      }
      &.search {
        margin   : 0px 4px;
        overflow : hidden;
        & .form-control {
          display : inline;
          width   : auto;
        }
      }
    }
  }

  /* Small devices (Smartphones, up to 768px) */
  & nav {
    //z-index : 9999;
    &.navbar {
      font-family      : "Open Sans Condensed", Helvetica, Arial, sans-serif;
      font-size        : 18px;
      font-weight      : 700;
      height           : auto;
      background-color : rgba(255, 255, 255, 0.70); // transparent;
      transition       : all .2s ease-out;
      & .navbar-header {
        height : inherit;
        & button.navbar-toggle {
          position         : absolute;
          padding          : 8px 14px;
          margin           : 0;
          background-color : @brand-primary;
          color            : #fff;
          border           : none;
          top              : 13px;
          right            : 16px;
          font-size        : 20px;
          &:hover {
            background-color : lighten(@brand-primary, 10%);
          }
        }
        & .navbar-brand {
          padding : 0px 15px;
          margin  : 0px;
          height  : 100%;
          & img {
            transition : all .2s ease-out;
            box-shadow : 0px 0px 5px rgba(0, 0, 0, 0.5);
            &.shield {
              position   : absolute;
              display    : block;
              margin     : 7px 0px 0px 0px;
              width      : 200px;
              transition : all 0.4s ease;
              &.hide {
                .transition(opacity 0.5s, margin 0.25s;);
                transition : all 0.4s ease;
              }
            }
          }
        }
      }
      & .navbar-collapse {
        & ul.navbar-nav {
          & > li > a {
            text-transform : uppercase;
            padding-left   : 8px;
            padding-right  : 8px;
          }
          & ul.dropdown-menu {
            font-family : "Open Sans";
          }
        }
      }
      &.affix {
        transition       : all .2s ease-out;
        position         : fixed;
        .navbar-fixed-top();
        box-shadow       : 0 2px 3px rgba(0, 0, 0, 0.3);
        background-color : #fff; //@gray-darker;
        & .navbar-header {
          & .navbar-brand {
            & img {
              box-shadow : none;
              &.shield {
                position   : absolute;
                display    : block;
                margin     : 5px 0px 0px 0px;
                width      : 225px;
                transition : all 0.4s ease;
                &.hide {
                  .transition(opacity 0.5s, margin 0.25s;);
                  transition : all 0.4s ease;
                }
              }
            }
          }
        }
      }
    }
  }

}

/* Small devices (tablets, 768px and up) */
@media (min-width : @screen-sm-min) {
  .navigation {

    & nav {
      &.navbar {
        height : auto;
        & .navbar-header {
          height : auto;
          & .navbar-brand {
            & img {
              transition : all .2s ease-out;
              &.shield {
                margin : -19px 0px 0px 0px;
                width  : 300px;
              }
            }
          }
        }
        & .navbar-collapse {
          & ul.navbar-nav {
            & > li > a {
              padding-left  : 10px;
              padding-right : 10px;
            }
          }
        }
        &.affix {
          //position         : fixed;
          //.navbar-fixed-top();
          & .navbar-header {
            & .navbar-brand {
              & img {
                &.shield {
                  width : 260px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
* Unstyled list
*/
.products-nav {
  display : block;
  & ul {
    list-style-type : none;
    margin          : 0;
    padding         : 10px;
    & li {
      margin      : 3px 0;
      padding     : 3px 0;
      font-size   : @font-size-base;
      font-weight : normal;
      &.active {
        font-weight : bold;
        &.caret-down {
          &:before {
            font-family : FontAwesome;
            content     : "\f0d7";
            position    : relative;
            left        : -5px;
            color       : @brand-primary;
            text-align  : center;
          }
        }
        &:not(.caret-down) {
          margin-left : 8px;
        }
      }
      &.caret-right {
        &:before {
          font-family : FontAwesome;
          content     : "\f0da";
          position    : relative;
          left        : -5px;
          color       : @text-color;
          text-align  : center;
        }
      }
      &:not(.caret-right) {
        margin-left : 8px;
      }

      & ul {
        list-style-type : none;
        margin          : 5px 0 3px 10px;
        padding         : 0px;
        & li {
          margin      : 0;
          padding     : 3px 0;
          font-size   : @font-size-base;
          font-weight : normal;
          &.active {
            font-weight : bold;
          }
          & ul {
            list-style-type : none;
            margin          : 5px 0 3px 10px;
            padding         : 0;
            & li {
              margin      : 0;
              padding     : 3px 0;
              font-size   : @font-size-base;
              font-weight : normal;
              &.active {
                font-weight : bold;
              }
            }
          }
        }
      }
    }
  }

  //list-style-type : none;
  //margin          : 0;
  //padding         : 3px 0 3px 10px;
  ////border:1px solid red;
  //& li {
  //  margin  : 0;
  //  padding : 3px 0 3px 0px;
  //  &:hover {
  //    color : @brand-primary;
  //  }
  //  &.active {
  //    &.caret-down {
  //      &:before {
  //        font-family      : FontAwesome;
  //        content          : "\f0d7";
  //        position         : relative;
  //        //float            : left;
  //        //width            : 17px;
  //        left: -5px;
  //        color            : @brand-primary;
  //        text-align: center;
  //        //background-color : green;
  //      }
  //    }
  //  }
  //  &.caret-right {
  //    &:before {
  //      font-family      : FontAwesome;
  //      content          : "\f0da";
  //      position         : relative;
  //      //float            : left;
  //      left            : -5px;
  //      color            : @text-color;
  //      text-align: center;
  //      //background-color : green;
  //    }
  //  }
  //}
  //}
  & a {
    color : @text-color;
  }
}

.products {
  &.sidebar {
    border        : none;
    padding-right : 15px;
    & a {
      display : block;
      color   : lighten(@text-color, 10%);
      & span.badge {
        background-color : transparent;
        border           : 1px solid lighten(@gray, 10%);
        color            : @gray;
      }

      &:before {
        font-family : FontAwesome;
        content     : "\f0fe"; //"\f105";
        position    : absolute;
        left        : 18px;
        width       : 10px;
        //font-size: @font-size-base*0.9;
        text-align  : center;
        color       : lighten(@text-color, 25%);
      }
      &:hover {
        background-color : @gray-lighter;
      }
      &.active {
        font-weight : bold;
        color       : @text-color;
        &:before {
          content : "\f146"; //"\f107";
          color   : darken(@brand-primary, 10%);
        }
      }

      &.level1 {
        padding       : 8px 12px 8px 28px;
        border-bottom : 1px solid @gray-light;
        &:first-child {
          border-top : 1px solid @gray-light;
        }
        &.active {
          border-bottom : none;
        }
      }
      &.level2 {
        padding       : 4px 12px 4px 40px;
        border-bottom : none;
        &:before {
          font-size  : @font-size-base*0.9;
          margin-top : 1px;
          left       : 32px;
        }
      }
      &.level3 {
        padding       : 4px 12px 4px 52px;
        border-bottom : none;
        &:before {
          font-size  : @font-size-base*0.9;
          margin-top : 1px;
          left       : 44px;
        }
      }
      &.level4 {
        padding       : 4px 12px 4px 62px;
        border-bottom : none;
        &:before {
          font-size  : @font-size-base*0.9;
          margin-top : 1px;
          left       : 56px;
        }
      }

      &.item {
        &:before {
          content    : "\f111";
          font-size  : @font-size-base*0.5;
          margin-top : 6px;
        }
        &.active {
          color : darken(@brand-primary, 10%);
          &:before {
            color : darken(@brand-primary, 10%);
          }
        }
      }
    }

  }
}

ul.list.list-unstyled {
  & li {
    display : block;
    & a {
      display     : block;
      color       : @text-color;
      font-size   : @font-size-base;
      line-height : 1.25;
      padding     : @padding-base-vertical*1.3 @padding-base-horizontal;
      & small, & span.small {
        font-weight : normal;
        color       : lighten(@text-color, 20%);
      }
      & img {
        filter        : grayscale(50%);
        margin-bottom : 5px;
      }
    }
    &:hover:not(.active) a {
      color            : @brand-primary;
      background-color : lighten(@gray-darker, 5%);
      & small, & span.small {
        font-weight : normal;
        color       : lighten(@brand-primary, 20%);
      }
      & img {
        filter : grayscale(0%);
      }
    }
    &.active a {
      background-color : @gray-lighter;
      font-weight      : bold;
      & small, & span.small {
        font-weight : normal;
        color       : lighten(@brand-primary, 20%);
      }
      & img {
        filter : grayscale(0%);
      }
    }
    &.link a {
      border : none;
      &:hover {
        background-color : transparent;
        color            : @brand-primary;
      }
    }
  }
  &.list-striped {
    & li {
      border-bottom : 1px dotted @gray-light;
      &:last-child {
        border-bottom : none;
      }
    }
  }
}

.media {
  & a {
    display : block;
    & .media-left {
      width : 25%;
      & img {
        filter : grayscale(50%);
      }
    }
    & .media-body {
      padding-left : 12px;
      & .media-heading {
        font-size   : @font-size-base*1.1;
        line-height : 1.2;
        color       : @text-color;
      }
      & small {
        color : lighten(@text-color, 20%);
      }
    }
    &:hover {
      background-color : @gray-lighter;
      & img {
        filter : grayscale(0%); //blur(0px)
      }
    }
  }
}

&.nav-tabs {
  & li {
    & a {
      color : lighten(@text-color, 20%);
    }
    &:hover {
      & a {
        color : @text-color;
      }
    }
    &:active, &.active {
      & a {
        color : @text-color;
        & i {
          color : @brand-primary;
        }
      }
    }
  }
}

.footer {
  #mc_embed_signup {
    background : transparant;
    clear      : left;
    font       : 14px Helvetica, Arial, sans-serif;
    label {
      color : @gray-light;
    }
    span.asterisk {
      color : red;
    }
  }
}

//#sidebar {
//  &.affix {
//    //position   : fixed;
//    //z-index    : 1003;
//    top   : 0;
//    right : 0;
//    //width      : 100%;
//    //box-shadow : 0 2px 3px rgba(0, 0, 0, 0.3);
//  }
//
//}
.modal.modalNav {
  background-color : rgba(255, 76, 41, 0.95);
  box-shadow       : none !important;
  & .modal-dialog {
    //box-shadow       : none !important;
    //border:1px solid rgba(255, 76, 41, 0.95);
    & .modal-content {
      background-color : transparent;
      box-shadow: none;
      border-radius: 0px;
      border:0px;
      text-align       : center;
      & a.brand {
        display    : block;
        text-align : center;
        width      : 350px;
        max-width  : 100%;
        padding    : 30px 60px 10px;
        margin     : 0 auto;
      }
      & ul {
        & li {
          margin : 3px 0px;
          & a {
            color     : #fff;
            opacity   : 0.6;
            font-size : @font-size-base*1.5;
          }
          &:hover a {
            opacity : 1;
          }
        }
        &.small li a {
          font-size : @font-size-base*1.2;
        }
      }
      &.show-nav {
        animation : anim .2s ease-in-out;
        opacity   : 1;
        display   : block;
      }
    }
  }
}

.fullscreen-nav {
  display          : none;
  background-color : rgba(255, 76, 41, 0.95);
  position         : absolute;
  top              : 0px;
  right            : 0px;
  bottom           : 0px;
  left             : 0px;
  width            : 100%;
  z-index          : 10000;
  text-align       : center;
  transition       : transform .2s ease-in-out, opacity .2s ease-in-out;
  & a.close {
    position : absolute;
    right    : 0px;
    display  : block;
    margin   : 20px;
    opacity  : 0.6;
    color    : #fff;
    &:hover a {
      opacity : 1;
    }
  }

  & a.brand {
    display    : block;
    text-align : center;
    width      : 350px;
    max-width  : 100%;
    padding    : 30px 60px 10px;
    margin     : 0 auto;
  }
  & ul {
    //.list-unstyled();
    & li {
      margin : 3px 0px;
      & a {
        color     : #fff;
        opacity   : 0.6;
        font-size : @font-size-base*1.7;
      }
      &:hover a {
        opacity : 1;
      }
    }
    &.small li a {
      font-size : @font-size-base*1.2;
    }
  }
  &.show-nav {
    animation : anim .2s ease-in-out;
    opacity   : 1;
    display   : block;
  }
}

@keyframes anim {
  0% {
    display : none;
    opacity : 0;
  }
  1% {
    display   : block;
    opacity   : 0;
    transform : scale(0);
  }
  100% {
    opacity   : 1;
    transform : scale(1);
  }
}

/* Positions the nav fixed below the push wrapper */
//nav {
//  width: 275px;
//  height: 100%;
//  position: fixed;
//  top: 0;
//  left: 0;
//}