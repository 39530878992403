/*
/*
* Home page blocks width calculation
*/
.col-md-3-0 {
  .make-sm-column(3.0);
  .make-md-column(3.0);
}

.col-md-3-4 {
  .make-sm-column(6.8);
  .make-md-column(3.4);
}

.col-md-4-0 {
  .make-sm-column(8.0);
  .make-md-column(4.0);
}

.col-md-4-8 {
  .make-sm-column(9.6);
  .make-md-column(4.8);
}

.col-md-6-0 {
  .make-sm-column(12.0);
  .make-md-column(6.0);
}

.col-md-8-0 {
  .make-sm-column(16.0);
  .make-md-column(8.0);
}

.col-md-12-0 {
  .make-sm-column(24.0);
  .make-md-column(12.0);
}

.col-md-24-0 {
  .make-sm-column(24.0);
  .make-md-column(24.0);
}

.home {
  &.wrapper {
    &.container, &.container-fluid {
      & h2, & h3, & h4 {
        &:first-child {
          margin-top : 0px;
        }
      }
    }
    & .pull-up, & .pull-up-de {
      //z-index:1000;
      margin-top:-325px;
      & a.column, div.column {
        color:#fff;
        & h3, h3, h4 {
          color:inherit;
          font-size:@font-size-h3*.8;
          font-weight:bolder;
          margin-top:7px;
          margin-bottom:7px;
        }
        &:hover {
          background-color: transparent;
          box-shadow: none;
          & img {
            box-shadow:0px 0px 25px #fff;
          }
          & h3 {
            color:@brand-primary;
          }
          //color:@text-color;
          //border-radius: 6px;
        }
      }
    }
    & .pull-up-de {
      margin-top:-387px;
    }
    & h2 {
      margin-bottom : 15px;
    }
    & img {
      display : block;
    }
    // Columns with redirect
    a.column {
      display : block;
      //visibility:hidden;
      //background-color : #fdfbcc;
      height:auto;
      color   : @text-color;
      padding : @padding-base-horizontal;
      & span.icon {
        display    : block;
        font-size  : @font-size-h1*1.5;
        text-align : center;
      }
      & span.image {
        display : block;
      }
      & span.text {
        display : block;
        padding : @padding-base-vertical*2 @padding-base-horizontal;
      }
      &:hover {
        transition : all 0.3s ease-in-out;
        box-shadow : 0px 0px 6px rgba(0, 0, 0, 0.5);
      }
      &.zoom {
        visibility:hidden;
        //transition : all 0.4s ease-in-out;
        & span.icon {
          display    : block;
          font-size  : @font-size-h1*1.5;
          text-align : center;
        }
        & span.image {
          display : block;
        }
        & span.text {
          display : none;
          & h2, & h3 {
            font-size:@font-size-h3*0.9;
          }
        }
        &:hover {
          z-index    : 999;
          position   : absolute;
          transition : all 0.4s ease-in-out;
          box-shadow : 0px 0px 6px rgba(0, 0, 0, 0.5);
          height   : auto !important;
          & span.text {
            display  : block;
            position : relative;
            padding  : @padding-base-vertical*2 @padding-base-horizontal;
            top      : 0;
            left     : 0;
          }
        }
      }
    }
    // Columns without redirect
    div.column {
      padding : @padding-base-horizontal;
      & div.icon {
        font-size  : @font-size-h1*1.5;
        text-align : center;
      }
      & div.image {
      }
      & div.text {
        padding : @padding-base-vertical*2 @padding-base-horizontal @padding-large-vertical*4;
        height  : auto;
        & a:not(.btn) {
          color : @brand-primary;
        }
        & a.bottom:last-child {
          position : absolute;
          bottom   : @padding-large-vertical*1.5;
        }
      }
    }
  }
}

table.homepage {
  & tr {
    & td {
      padding : 2px 4px;
    }
  }
}

