.pdf {
  color     : #7b7c7f;
  font-size : 0.8em;
  & h2, & h3, & h4 {
    color          : #ff4c29;
    text-transform : uppercase;
    font-weight    : 100 !important;
    & strong {
      color       : #7b7c7f;
      font-weight: normal;
    }
  }
  & h2 {
    font-size     : 3em;
    margin-bottom : 0px;
  }
  & h3 {
    font-size : 1.3em;
  }
  & h4 {
    font-size   : 1em;
    font-weight : bold;
  }
  & .main_img {
    position : relative;
    display  : block;
    .bgimg {
      background-position : center center;
      background-size     : cover;
      width               : 100%;
      height              : 430px;
    }
  }
  & .main_legend {
    display          : block;
    position         : absolute;
    top              : 0; //2em;
    left             : 0; //2.7em;
    bottom           : 0; //2em;
    padding          : 20px 40px;
    background-color : rgba(0, 0, 0, 0.40);
    & h2 {
      margin      : 0;
      font-size   : 2em;
      white-space : nowrap;
      & strong {
        color : #f8fbff;
      }
    }
    & table {
      color : #fff;
      & tr {
        & td {
          border : none;
          & h4 {
            margin : 10px 0 0 0;
          }
          &:first-child {
            font-weight : bold;
          }
        }

      }
    }
  }

  & .sidebar {
    background-color : #f7f7f7;
    & img {
      width  : 100%;
      height : auto;
    }
    & .well {
      display             : block;
      background-color    : #e3eef7;
      background-repeat   : no-repeat;
      background-size     : 100% auto;
      background-position : center top;
      margin-top          : 60px;
      padding             : 15px 20px;
      color               : #7997a6;
      & h3 {
        color         : #fff;
        margin-top    : 0px;
        margin-bottom : 60px;
        //background-color : #00a000;
      }
    }
  }

  & .content {
    & p {
      padding-bottom : 30px;
    }
    & .credentials {
      font-weight   : 300;
      font-style    : italic;
      margin-bottom : 40px;
    }
  }
  & .gallery {
    & img {
      margin-bottom : @grid-gutter-width;
    }
  }

}

.pdf-header {
  background-color : @brand-primary;
  font-size        : 0.6em;
  color            : #fff;
  height      : 150px;
  line-height      : 1;
}

.pdf-footer {
  height:115px;
  margin-bottom:0px;
  font-size        : 0.7em;
  color            : #fff;
  line-height      : 1.2;
    & div.pdf-logo {
      height:115px;
      padding:0px;
    }
    & div.pdf-address {
      height:115px;
      padding:30px 0 0 40px;
    }
  }

