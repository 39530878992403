//.well {
//  &.form {
//    margin : 30px 0;
//  }
//}
.form {

  margin-top : 30px;
  & fieldset {
    margin-bottom : 30px;
    & legend {
      //display        : none;
      margin-bottom : 15px;
      font-family   : @headings-font-family;
      font-weight   : @headings-font-weight;
      font-size     : @font-size-h3;
      //font-size      : @font-size-base*1.2;
      //text-transform : uppercase;
      border        : none;
      //color          : red;
      & span {
        text-transform : none;
      }
    }

    //& span.help-block {
    //  position: relative;
    //  margin: -15px 0 20px 0;
    //  padding-left: 15px;
    //  &:before {
    //    position: absolute;
    //    font-family: "fontAwesome";
    //    content: "\f05a";
    //    left: 0px;
    //    color: darken(@brand-secundary, 30%);
    //    font-size: @font-size-base;
    //  }
    //
    //}
  }
  & label {
    font-weight  : 700;
    color        : rgba(0, 0, 0, 0.5);
    margin-right : 5px;
    &.required {
      &:after {
        font-family : "fontAwesome";
        content     : " \f069";
        position    : absolute;
        margin      : 0 0 0 4px;
        //right       : -6px;
        color       : red;
        font-size   : @font-size-base*0.5;
        font-weight : normal;
      }
    }
  }
  /*
  This section controls the widths of the controls (Normal form)
   */
  & .form-control, & .input-group {
    &.form-75 {
      width : 100%;
    }
    &.form-50 {
      width : 75%;
    }
    &.form-25 {
      width : 50%;
    }
    &.form-10 {
      width : 25%;
    }
  }
  & span.text-danger {
    font-size   : @font-size-base*0.8;
    line-height : 1.2;
    color       : rgba(255, 0, 0, 0.5);
  }
}

/* Small devices (tablets, 768px and up) */
//@media (min-width : @screen-sm-min) {
//  .form {
//    & .form-control, & .input-group {
//      &.form-75 {
//        width : 75%;
//      }
//      &.form-50 {
//        width : 50%;
//      }
//      &.form-25 {
//        width : 25%;
//      }
//      &.form-10 {
//        width : 10%;
//      }
//    }
//  }
//}

/* Medium devices (desktops, 992px and up) */
@media (min-width : @screen-md-min) {
  .form {
    & .form-control, & .input-group {
      &.form-75 {
        width : 75%;
      }
      &.form-50 {
        width : 50%;
      }
      &.form-25 {
        width : 25%;
      }
      &.form-10 {
        width : 10%;
      }
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width : @screen-lg-min) {
  .form {
    & .form-control, & .input-group {
      &.form-75 {
        width : 75%;
      }
      &.form-50 {
        width : 50%;
      }
      &.form-25 {
        width : 25%;
      }
      &.form-10 {
        width : 10%;
      }
    }
  }
}

//.panel {
//  & .panel-heading {
//    & h3.panel-title {
//      margin-bottom  : 0px;
//      padding-bottom : 0;
//      line-height    : 1;
//    }
//  }
//  & i {
//    color : @gray-light;
//  }
//  & a {
//    &:hover, &:active {
//      & i {
//        color : @brand-primary;
//      }
//    }
//  }
//}

