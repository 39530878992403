// index
.preowned {
  & div {
    & a {
      position : relative;
      color   : @text-color;
      display : block;
& table {margin:0}
      & .tagLabel {
        position         : absolute;
        display          : block;
        margin           : 0px;
        right            : 0px;
        top           : 15%;
        padding          : @padding-base-vertical/2 @padding-base-horizontal;
        font-family      : "Lato", Helvetica, Arial, sans-serif;
        font-weight      : 900;
        background-color : @brand-primary;
        color            : #ffffff;
      }
      & i:not(.text-muted) {
        color : @brand-primary;
        &.normal {
          color : @text-color;
        }
        //&.light {
        //  color : lighten(@gray-light, 30%);
        //}
      }
      & h3 {
        //margin: 8px 0px 4px;
        margin           : 0px;
        background-color : @brand-primary;
        color            : #ffffff;
        font-size        : @font-size-h3*0.7;
        padding          : @table-cell-padding;
      }
      .table tr:first-child td {
        border-top : none;
      }

    }

  }
}
