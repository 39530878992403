h1, h2, h3, h4, h5, h6 {
  color: lighten(@text-color, 10%);
  text-transform: uppercase;
  //& small, & span.small {
  //  font-weight:normal !important;
  //}
}

h1 {
  text-transform: uppercase;
  //font-family: @font-family-serif;

}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

& a {
  color: @brand-primary;
  &:hover {
    text-decoration: none;
    color: lighten(@brand-primary, 20%);
    //    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
  &.disabled {
    opacity: 0.5;
  }
}

small {
  &.light {
    color: lighten(@text-color, 20%);
  }
  &.dark {
    color: darken(@text-color, 20%);
  }
}

u {
  text-decoration: none;
}

.page-header {
  //background-color: #00a0b0 ;
  & h1, & h2, & h3 {
    margin-top: 0px;
    color: @brand-primary;
  }
  padding-bottom: ((@line-height-computed / 2) - 1);
  margin: (@line-height-computed * 2) 0 @line-height-computed;
  border-bottom: 1px solid @brand-primary; //@page-header-border-color;
}

.content {
  & ul, ul.list {
    &.list-unstyled {
      // FAQ menu left
      &.summary {
        margin: 0px 0px 10px;
        & li {
          & a {
            display: block;
            color: @gray-light;
            padding-left: 0.8em;
            &.ellipsis {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            &:hover {
              color: @brand-primary;
            }
            &:before {
              position: absolute;
              width: 0.8em;
              margin-left: -0.8em;
              font-family: FontAwesome;
              content: "\f105";
              display: inline-block;
              color: @brand-primary;
              //padding-right: 3px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion):not(.nav-pills):not(.nav-tabs) {
    list-style-type: none;
    padding-left: 0px;
    margin: 20px 0 20px 20px; // 0 0 15px;
    & li {
      margin: 4px 0px;
      padding-left: 10px;
      &:before {
        content: '\f105';
        font-family: 'FontAwesome';
        color: @brand-primary;
        float: left;
        margin: 0 0 0 -15px;
        font-weight: 600;
      }
    }
  }

  & table {
    &.specs {
      & tr {
        & td {
          padding: 2px 5px 2px 0px;
        }
      }
    }
    &.styled {
      //font-size: @font-size-base*0.7;
      background-color: lighten(@gray-lighter, 5%);
      border: none;
      & thead {
        & th {
          background-color: darken(@gray-lighter, 5%);
          border: none;
          border-bottom: 1px solid @gray-lighter;

          //border-bottom: 3px solid #fff;
          text-transform: uppercase;
          vertical-align: bottom;
          //text-align: center;
          color: @text-color;
        }
        & th[scope=row] {
          border: none;
          background-color: @brand-primary;
          color: #fff;
        }
      }
      & tbody {
        & td {
          border: none;
          background-color: inherit;
          border-bottom: 1px solid @gray-light;
        }
      }
      & th[scope=row] {
        color: #fff;
        border: none;
        border-bottom: 1px solid @gray-light;
      }

    }
  }

  // Blockquotes
  & blockquote {
    padding: 15px;
    margin: 10px 0;
    font-size: @font-size-base * 1.2;
    border: none;
    font-weight: 100;
    font-style: italic;
    line-height: 1.4;
    //background-color: Lighten(@gray-lighter,2%);
    //background-color: #daf3ff;
    //.bg-primary().lighter();
    //background-color : #e3eef7;
    p,
    ul,
    ol {
      &:last-child {
        margin-bottom: 0;
      }
    }
    quotes: "\201C" "\201D" "\2018" "\2019";
    & > *:before {
      content: open-quote;
    }
    & > *:after {
      content: close-quote;
    }
    .small {
      color: @gray;
    }
  }

}

//.table.styled {
//  //font-size: @font-size-base*0.7;
//  background-color : lighten(@gray-lighter, 5%);
//  border           : none;
//  & thead {
//    & th {
//      background-color : darken(@gray-lighter, 5%);
//      border           : none;
//      border-bottom    : 1px solid @gray-lighter;
//
//      //border-bottom: 3px solid #fff;
//      text-transform   : uppercase;
//      vertical-align   : bottom;
//      //text-align: center;
//      color            : @text-color;
//    }
//    & th[scope=row] {
//      border           : none;
//      background-color : @brand-primary;
//      color            : #fff;
//    }
//  }
//  & tbody {
//    & td {
//      border           : none;
//      background-color : inherit;
//      border-bottom    : 1px solid @gray-light;
//    }
//  }
//  & th[scope=row] {
//    color         : #fff;
//    border        : none;
//    border-bottom : 1px solid @gray-light;
//  }
//}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
  blockquote {
    //padding : @padded*3;
    //margin  : @padded*3 0;
  }
}

// Mixin and adjust the regular image class
//.thumbnail {
//  display          : block;
//  padding          : @thumbnail-padding;
//  margin-bottom    : @line-height-computed;
//  line-height      : @line-height-base;
//  background-color : @thumbnail-bg;
//  border           : @thumbnail-border;
//  border-radius    : @thumbnail-border-radius;
//  .transition(border .2s ease-in-out);
//
//  > img,
//  a > img {
//    &:extend(.img-responsive);
//    margin-left  : auto;
//    margin-right : auto;
//  }
//
//  // Add a hover state for linked versions only
//  a&:hover,
//  a&:focus,
//  a&.active {
//    border-color : @link-color;
//  }
//
//  // Image captions
//  .caption {
//    padding : @thumbnail-caption-padding;
//    color   : @thumbnail-caption-color;
//  }
//}

//form.form-horizontal {
//  & span.help-block {
//    .small();
//  }
//}
@media (min-width: @screen-sm-min) {

  .columns {
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px;
    &.columns-2 {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    &.columns-3 {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    &.columns-4 {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
  }
}

//  th[scope=row] { border-right: 2px solid #333; } { border-right: 2px solid #333; }
//}
a {
  &.fa_linkedin {
    color: @linkedin;
    &:hover {
      color: lighten(@linkedin, 10%);
    }
  }
  &.fa_skype {
    color: @skype;
    &:hover {
      color: lighten(@skype, 10%);
    }
  }
  &.fa_facebook {
    color: @facebook;
    &:hover {
      color: lighten(@facebook, 10%);
    }
  }
  &.fa_twitter {
    color: @twitter;
    &:hover {
      color: lighten(@twitter, 10%);
    }
  }
  &.fa_googleplus {
    color: @googleplus;
    &:hover {
      color: lighten(@googleplus, 10%);
    }
  }
  &.fa_youtube {
    color: @youtube;
    &:hover {
      color: lighten(@youtube, 10%);
    }
  }
  &.fa_instagram {
    color: @instagram;
    &:hover {
      color: lighten(@instagram, 10%);
    }
  }
}